import React, { PureComponent } from 'react';

import Box from '../atoms/Box/Box';
import Text from '../atoms/Text/Text';

import { Align } from '@shared/enums/align';
import { I18nTranslation } from '@shared/interfaces/i18n';
import { withTranslation } from 'i18n';

type ComparisonChartHeaderProps = {
  title?: string;
  subTitle?: string;
  t: I18nTranslation;
};

class ComparisonChartHeader extends PureComponent<ComparisonChartHeaderProps> {
  t = key => this.props.t(`comparePlans.${key}`);

  render() {
    const { subTitle } = this.props;

    return (
      <Box smMr={1.5} smMl={1.5} mb={3.125}>
        <Box align={Align.Center}>
          <Text
            smSize={2.25}
            size={3.125}
            weight="bold"
            color="cornflowerBlue"
            lineHeight="normal"
          >
            {this.t('title')}
          </Text>
        </Box>

        {subTitle && (
          <Box align={Align.Center} smMt={0.75} mt={1.375}>
            <Text
              smSize={1.25}
              size={1.5}
              color="cornflowerBlue"
              lineHeight="normal"
            >
              {this.t('subTitle')}
            </Text>
          </Box>
        )}
      </Box>
    );
  }
}

export default withTranslation('common')(ComparisonChartHeader);
