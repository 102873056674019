import React, { PureComponent } from 'react';

import styled, { css } from 'styled-components';

import Box from '../atoms/Box/Box';
import { Col, Row } from '../atoms/Grid';
import Text from '../atoms/Text/Text';
import NewFeature from '@components/molecules/NewFeature';
import SubtitleTooltip from '@components/molecules/SubtitleTooltip';

import CheckIcon from '@public/icons/check.svg';
import { ComparisonItemValue } from '@shared/interfaces/comparison';
import PlayIcon from '@public/icons/play.svg';

import { breakpoint } from 'theme';

const CheckIconExtend = styled(CheckIcon)`
  color: ${props => props.theme.colors.turquoise};

  ${breakpoint.m`
    width: 24px;
    height: 24px;
  `}
`;

const sharedColMy = css`
  padding-top: 0.75em;
  padding-bottom: 0.75em;
`;

const RowWrapper = styled(Row)`
  border-top: 1px solid ${props => props.theme.colors.iron};

  ${breakpoint.down('ltm')`
    border-top: none;
  `}
`;

const ColWrapper = styled(Col)`
  display: flex;
  align-items: center;
  ${sharedColMy};

  ${breakpoint.down('ltm')`
    padding-top: 1.125em;
  `}
`;

const BoxWrapper = styled(Box)`
  ${breakpoint.lg`
    display: flex;
  `}
`;

const ColValue = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  ${sharedColMy};
  border-left: 1px solid ${({ theme }) => theme.colors.iron};
  ${({ isMarked, theme }) =>
    isMarked &&
    `
    background-color: ${theme.transparentColors.turquoise10};
  `}

  ${breakpoint.down('ltm')`
    border-top: 1px solid ${({ theme }) => theme.colors.iron};
    border-bottom: 1px solid ${({ theme }) => theme.colors.iron};

    &:first-child {
      border-left: none;
    }
  `}
`;

const RowValue = styled(Row)`
  height: 100%;
  justify-content: center;

  ${breakpoint.m`
    justify-content: flex-start;    
  `}
`;

const BoxName = styled(Box)`
  display: flex;
  align-items: center;
`;

type ComparisonChartSubFeatureProps = {
  name: string;
  video?: string;
  isNew?: boolean;
  values?: ComparisonItemValue[];
  bundles: string[];
  openVideo: (url: string) => void;
  asterisk?: boolean | string;
  icon?: string;
  subtitles?: string;
};

class ComparisonChartSubFeature extends PureComponent<ComparisonChartSubFeatureProps> {
  renderValue = (value, isMarked) => {
    switch (value) {
      case 'True':
        return <CheckIconExtend />;
      case 'False':
        return (
          <Box backgroundColor="silverSand" width={0.625} height={0.125} />
        );
      default:
        return (
          <Text
            smSize={0.875}
            size={1}
            lgSize={1.125}
            color={isMarked ? 'turquoise' : 'silverSand'}
            weight="bold"
            lineHeight="normal"
          >
            {value}
          </Text>
        );
    }
  };

  render() {
    const { name, video, isNew, values, bundles, asterisk, icon, subtitles } =
      this.props;

    return (
      <RowWrapper gutter={0}>
        <ColWrapper xs={12} sm={6}>
          <BoxWrapper smPl={1} smPr={1}>
            <BoxName>
              <Text color="cornflowerBlue" smSize={1} size={1.125}>
                {`${name}${asterisk ? '*' : ''}`}
              </Text>
              {video && (
                <PlayIcon
                  onClick={this.props.openVideo.bind(this, video)}
                  style={{
                    cursor: 'pointer',
                    marginLeft: '0.5em',
                  }}
                />
              )}
              {icon && <SubtitleTooltip title={subtitles} icon={icon} />}
            </BoxName>
            {isNew && <NewFeature />}
          </BoxWrapper>
        </ColWrapper>
        <Col xs={12} sm={6}>
          <RowValue gutter={0}>
            {bundles.map((bundle, i) => {
              const value = values.find(value => value.bundle === bundle);

              return (
                <ColValue
                  key={i}
                  xs={12 / bundles.length}
                  isMarked={value?.is_marked}
                >
                  {this.renderValue(value?.value, value?.is_marked)}
                </ColValue>
              );
            })}
          </RowValue>
        </Col>
      </RowWrapper>
    );
  }
}

export default ComparisonChartSubFeature;
