import React, { PureComponent } from 'react';

import styled from 'styled-components';

import Box from '../atoms/Box/Box';
import Text from '../atoms/Text/Text';

import CollapseArrowGreenIcon from '@public/icons/collapseArrowGreen.svg';

import { I18nTranslation } from '@shared/interfaces/i18n';
import { withTranslation } from 'i18n';

const Wrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;

  color: ${({ theme }) => theme.colors.turquoise};
`;

const Icon = styled(CollapseArrowGreenIcon)`
  width: 19px;
  height: 12px;
`;

type ComparePlansProps = {
  onClick: () => void;
  t: I18nTranslation;
};

class ComparePlans extends PureComponent<ComparePlansProps> {
  handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    this.props.onClick();
  };

  render() {
    const { t } = this.props;

    return (
      <Wrapper onClick={this.handleClick}>
        <Icon />
        <Box mr={2} />
        <Text smSize={1.625} size={2} lineHeight="normal" color="turquoise">
          {t('comparePlans.title')}
        </Text>
        <Box ml={2} />
        <Icon />
      </Wrapper>
    );
  }
}

export default withTranslation('common')(ComparePlans);
