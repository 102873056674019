import React, { FC } from 'react';

import styled from 'styled-components';

import Box from '../atoms/Box/Box';
import Text from '../atoms/Text/Text';

import { I18nTranslation } from '@shared/interfaces/i18n';
import { breakpoint } from 'theme';
import { withTranslation } from 'i18n';

type NewFeatureProps = {
  ml?: number;
  t: I18nTranslation;
};

const NewFeature: FC<NewFeatureProps> = ({ ml, t: tProp }) => {
  const t = key => tProp(`features.${key}`);

  return (
    <Box ml={ml || 0.5}>
      <StyledText
        component="div"
        color="white"
        size={1}
        lgSize={1.125}
        lineHeight="normal"
      >
        {t('new')}
      </StyledText>
    </Box>
  );
};

export default withTranslation('common')(NewFeature);

const StyledText = styled(Text)`
  position: relative;
  background-color: ${({ theme }) => theme.colors.turquoise};
  padding: 0.375em 1.625em 0.375em 0.75em;
  white-space: nowrap;

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 16px 16px 16px 0;
    border-color: transparent #fff transparent transparent;
    position: absolute;
    right: 0;
    top: 0;
  }

  ${breakpoint.lg`
    &:after {
      border-width: 18px 16px 18px 0;
    }
  `}
`;
