import React, { PureComponent } from 'react';

import styled from 'styled-components';

import Image from '../atoms/Image/Image';

import { breakpoint } from 'theme';

const PositionBorder = styled.span`
  position: relative;
`;

const Icon = styled(Image)`
  margin-left: 0.5em;
`;

const Subtitle = styled.div`
  display: none;
  position: absolute;
  z-index: 1;
  left: 2.6em;
  top: -0.3em;
  ${props => `
    background-color: ${props.theme.colors.cornflowerBlue};
    color: ${props.theme.colors.white};  
  `};
  box-shadow: 0 0 0.5em 0 ${({ theme }) => theme.transparentColors.black25};
  padding: 0.4em 0.875em 0.525em;
  border-radius: 0.25em;
  min-width: 15.5em;

  ${PositionBorder}:hover & {
    display: block;
  }

  :after {
    content: '';
    position: absolute;
    left: -0.75em;
    top: 30%;
    width: 0;
    height: 0;
    border-top: 0.4em solid transparent;
    border-bottom: 0.4em solid transparent;
    border-right: 0.75em solid ${props => props.theme.colors.cornflowerBlue};
    clear: both;
  }

  ${breakpoint.down('ltm')`
    left: auto;
    right: -0.8em;
    top: 2.25em;
    min-width: 10em;
    
    :after {
      left: auto;
      right: 1em;
      top: -1.2em;
      border-left: 0.5em solid transparent;
      border-right: 0.5em solid transparent;
      border-bottom: 0.75em solid ${props => props.theme.colors.cornflowerBlue};
    }
  `}
`;

const Title = styled.div`
  display: inline;
  font-weight: bold;
  font-size: 0.875em;
  white-space: nowrap;
`;

const SubtitleText = styled(Title)`
  font-weight: normal;
  white-space: nowrap;

  ${breakpoint.down('ltm')`
    white-space: pre-wrap;
  `}
`;

type SubtitleTooltipProps = {
  title: string;
  icon: string;
};

class SubtitleTooltip extends PureComponent<SubtitleTooltipProps> {
  componentDidMount() {
    document.addEventListener('touchstart', () => {}); // required for the hover effect
  }

  componentWillUnmount() {
    document.removeEventListener('touchstart', () => {});
  }

  render() {
    return (
      <PositionBorder>
        <Icon width="20px" height="20px" src={this.props.icon} />
        <Subtitle>
          <Title>{this.props.title.split(':')[0]}</Title>
          <SubtitleText>: {this.props.title.split(':')[1]}</SubtitleText>
        </Subtitle>
      </PositionBorder>
    );
  }
}

export default SubtitleTooltip;
