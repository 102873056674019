import React, { PureComponent } from 'react';

import Sticky from 'react-sticky-el';

import styled from 'styled-components';

import Box from '../atoms/Box/Box';
import ComparisonChartHeader from '../molecules/ComparisonChartHeader';
import ComparisonChartSubscriptions from '../molecules/ComparisonChartSubscriptions';
import ComparisonChartFeature from '@components/organisms/ComparisonChartFeature';
import VideoPopup from 'components/organisms/VideoPopup';

import { Comparison } from '@shared/interfaces/comparison';
import { Subscription } from '@shared/interfaces/subscription';

const Wrapper = styled.div`
  padding-top: 4em;
`;

type ComparisonChartProps = {
  title?: string;
  subTitle?: string;
  subscriptions?: Partial<Subscription>[]; // { name, icon, bundle }
  features: Comparison[];
};

class ComparisonChart extends PureComponent<ComparisonChartProps> {
  state = {
    videoUrl: '',
  };

  handleOpenVideo = (url: string) => {
    this.setState({
      videoUrl: url,
    });
  };

  handleCloseVideo = () => {
    this.setState({
      videoUrl: '',
    });
  };

  render() {
    const { subscriptions, features, title, subTitle } = this.props;

    const bundles = subscriptions.map(subscription =>
      subscription?.bundle?.toLowerCase()
    );
    const content = (
      <Box className="features-table-boundary">
        <Sticky
          topOffset={-50}
          bottomOffset={50}
          stickyStyle={{ zIndex: 1 }}
          boundaryElement=".features-table-boundary"
          scrollElement="#layout-wrapper"
        >
          <ComparisonChartSubscriptions subscriptions={subscriptions} />
        </Sticky>

        <Box smPt={1.75} pt={2.75} lgPt={3.125} smMb={3.375} mb={5} lgMb={7.75}>
          {features.map((feature, i) => {
            return (
              <ComparisonChartFeature
                key={i}
                feature={feature}
                bundles={bundles}
                openVideo={this.handleOpenVideo}
              />
            );
          })}
        </Box>
      </Box>
    );

    return (
      <Wrapper>
        <Box maxWidth={71} id="features-table">
          <ComparisonChartHeader title={title} subTitle={subTitle} />
          {content}
        </Box>

        {this.state.videoUrl && (
          <VideoPopup
            onClose={this.handleCloseVideo}
            url={this.state.videoUrl}
          />
        )}
      </Wrapper>
    );
  }
}

export default ComparisonChart;
